/* stylelint-disable color-function-notation -- need rgba function */
/* stylelint-disable alpha-value-notation -- percentages don't work? */
/* stylelint-disable comment-empty-line-before */

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("assets/background.jpg");
  /* fallback if background image unavailable */
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  padding: 6vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2d2d2d;
  background-color: white;
  font-size: calc(14px + 1.5vmin);
  height: max-content;
  width: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

svg {
  width: calc(50px + 1.5vmin);
  padding: 1vmin;
  fill: none;
  stroke: #a4a3a3;
  stroke-width: 1px;
  stroke-linejoin: round;
}

.github-logo:hover {
  stroke: #161B22;
}

.linkedin-logo:hover {
  stroke: #2160AC;
}

.leetcode-logo:hover {
  stroke: #F5A537;
}

.stackoverflow-logo:hover {
  stroke: #F48024;
}

h1,
h2,
p {
  padding: calc(10px + 1vmin);
  margin: 0;
  font-family:
    Lato,
    Roboto,
    sans-serif;
}
